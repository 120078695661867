/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";

import { Grid, Typography, Box, InputAdornment } from "@mui/material";

import ModifiedTextField from "./modified-field/modified-text-field";

import { RecipeEntityContext } from "./hooks/entity-context";
import useRecipeInstruction from "./hooks/use-recipe-instruction";
import {
  heatLevel,
  manualHeatOptionns,
  manualHeatOptions,
  rikuCompatible,
  semiCompatible,
} from "./constants";
import ModifiedAutoCompleteWithCookingActions from "./modified-field/modified-for-cooking-actions";

const stylesheet = {
  servingsButton: {
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "8px",
    marginRight: "7px",
    boxShadow: " 0px 0px 4px 0px rgba(0, 0, 0, 0.05)",
    textTransform: "capitalize",
    "&:hover": {
      // border: "1px solid #EF4823 ",
      background: "#FFECE4",
    },
  },
};

export default function CookingParameter(props) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const isError = errors?.cookingParameters;
  const { modeSets, cookingPresets } = useContext(RecipeEntityContext);
  const {
    sectionId,
    instructionArrayIndex,
    cookingParameters,
    cookingActions,
    mentionTitleArray,
    selectedPreset,
    handleSelectedPreset,
    instructionId,
  } = props;

  const { setCookingAction, getHeatLevel, selectHeatLevelHandler } =
    useRecipeInstruction({
      sectionId,
      instructionId,
      instructionArrayIndex,
      mentionTitleArray,
      handleSelectedPreset,
    });

  const filterSection = getValues("recipeSections").filter(
    (item) => item.id === sectionId
  );
  const metaData = getValues("metadata");
  const [filterControlModes, setFilterControlModes] = useState({});
  const [lidOpen, setLidOpen] = useState(cookingParameters?.isLidOpen);
  const [stirring, setStirring] = useState(cookingParameters?.stirring);

  const handleLidSelection = (item) => {
    setLidOpen(item === "on");
    if (item === "on") {
      setValue(`cookingParameters.${instructionArrayIndex}.isLidOpen`, true);
    } else {
      setValue(`cookingParameters.${instructionArrayIndex}.isLidOpen`, false);
    }
  };

  const handleStirringSelected = (value) => {
    setStirring(value);
    setValue(`cookingParameters.${instructionArrayIndex}.stirring`, value);
  };

  const checkingCookingParametersStatues = (params1) => {
    if (["REQUIRED", "OPTIONAL"].includes(params1)) {
      return false;
    } else {
      return true;
    }
  };

  const checkingManualCookingParameters = (params1) => {
    if (["heat"].includes(params1)) {
      return true;
    } else {
      return false;
    }
  };
  const checkingManualCookingParametersTime = (params1) => {
    if (["add", ""].includes(params1)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setFilterControlModes(
      modeSets.find((item) => item.id === selectedPreset?.controlModeId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPreset]);

  useEffect(() => {
    setFilterControlModes(
      modeSets.find((item) => item.id === selectedPreset?.controlModeId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructionArrayIndex]);

  // for rendering  the correct type of field based on the cooking action
  const renderingCookingParameter = () => {
    return (
      <Grid xs={12} container spacing={2}>
        <Grid item display="flex" flexDirection="column">
          <Typography
            variant="subtext1"
            sx={{
              opacity: checkingCookingParametersStatues(
                filterControlModes?.isCentralProbeTemperatureRequired
              )
                ? 0.5
                : 1,
            }}
          >
            Pan Temp
            {!checkingCookingParametersStatues(
              filterControlModes?.isCentralProbeTemperatureRequired
            ) && <span style={{ color: "#EF4823" }}> *</span>}
          </Typography>

          <ModifiedTextField
            fullWidth
            variant="outlined"
            placeholder="-"
            type="number"
            size="small"
            error={
              isError && isError[instructionArrayIndex]?.centralTemperatureInC
            }
            style={{
              width: "100px",
            }}
            control={control}
            name={`cookingParameters.${instructionArrayIndex}.centralTemperatureInC`}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  opacity:
                    checkingCookingParametersStatues(
                      filterControlModes?.isCentralProbeTemperatureRequired
                    ) && 0.5,
                }}
              >
                <Typography variant="body4">&#8451;</Typography>
              </InputAdornment>
            }
            disabled={checkingCookingParametersStatues(
              filterControlModes?.isCentralProbeTemperatureRequired
            )}
          />
          {isError &&
            !checkingCookingParametersStatues(
              filterControlModes?.isCentralProbeTemperatureRequired
            ) && (
              <Typography variant="caption1" color="#EF4823">
                {isError[instructionArrayIndex]?.centralTemperatureInC?.message}
              </Typography>
            )}
        </Grid>

        <Grid item display="flex" flexDirection="column">
          <Typography
            variant="subtext1"
            sx={{
              opacity: checkingCookingParametersStatues(
                filterControlModes?.isExternalProbeTemperatureRequired
              )
                ? 0.5
                : 1,
            }}
          >
            Probe Temp
            {!checkingCookingParametersStatues(
              filterControlModes?.isExternalProbeTemperatureRequired
            ) && <span style={{ color: "#EF4823" }}> *</span>}
          </Typography>

          <ModifiedTextField
            fullWidth
            variant="outlined"
            type="number"
            placeholder="-"
            size="small"
            error={
              isError && isError[instructionArrayIndex]?.externalTemperatureInC
            }
            style={{
              width: "100px",
            }}
            control={control}
            name={`cookingParameters.${instructionArrayIndex}.externalTemperatureInC`}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  opacity:
                    checkingCookingParametersStatues(
                      filterControlModes?.isExternalProbeTemperatureRequired
                    ) && 0.5,
                }}
              >
                <Typography variant="body4">&#8451;</Typography>
              </InputAdornment>
            }
            disabled={checkingCookingParametersStatues(
              filterControlModes?.isExternalProbeTemperatureRequired
            )}
          />
          {isError &&
            !checkingCookingParametersStatues(
              filterControlModes?.isExternalProbeTemperatureRequired
            ) && (
              <Typography variant="caption1" color="#EF4823">
                {
                  isError[instructionArrayIndex]?.externalTemperatureInC
                    ?.message
                }
              </Typography>
            )}
        </Grid>

        <Grid item display="flex" flexDirection="column">
          <Typography
            variant="subtext1"
            sx={{
              opacity: checkingCookingParametersStatues(
                filterControlModes?.isPowerRequired
              )
                ? 0.5
                : 1,
            }}
          >
            Power
            {!checkingCookingParametersStatues(
              filterControlModes?.isPowerRequired
            ) && <span style={{ color: "#EF4823" }}> *</span>}
          </Typography>

          <ModifiedTextField
            fullWidth
            variant="outlined"
            type="number"
            placeholder="-"
            size="small"
            error={isError && isError[instructionArrayIndex]?.powerInLevel}
            style={{
              width: "100px",
            }}
            inputProps={{ max: 11 }}
            control={control}
            name={`cookingParameters.${instructionArrayIndex}.powerInLevel`}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  opacity:
                    checkingCookingParametersStatues(
                      filterControlModes?.isPowerRequired
                    ) && 0.5,
                }}
              >
                {" "}
                <Typography variant="body4">level</Typography>
              </InputAdornment>
            }
            disabled={checkingCookingParametersStatues(
              filterControlModes?.isPowerRequired
            )}
          />
          {isError &&
            !checkingCookingParametersStatues(
              filterControlModes?.isPowerRequired
            ) && (
              <Typography variant="caption1" color="#EF4823">
                {isError[instructionArrayIndex]?.powerInLevel?.message}
              </Typography>
            )}
        </Grid>

        <Grid item display="flex" flexDirection="column">
          <Typography
            variant="subtext1"
            sx={{
              opacity: checkingCookingParametersStatues(
                filterControlModes?.isTimeRequired
              )
                ? 0.5
                : 1,
            }}
          >
            Time
            {!checkingCookingParametersStatues(
              filterControlModes?.isTimeRequired
            ) && <span style={{ color: "#EF4823" }}> *</span>}
          </Typography>

          <ModifiedTextField
            fullWidth
            variant="outlined"
            type="number"
            placeholder="-"
            size="small"
            error={isError && isError[instructionArrayIndex]?.durationInMins}
            style={{
              width: "100px",
            }}
            control={control}
            name={`cookingParameters.${instructionArrayIndex}.durationInMins`}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  opacity:
                    checkingCookingParametersStatues(
                      filterControlModes?.isTimeRequired
                    ) && 0.5,
                }}
              >
                <Typography variant="body4">mins</Typography>
              </InputAdornment>
            }
            disabled={checkingCookingParametersStatues(
              filterControlModes?.isTimeRequired
            )}
          />
          {isError &&
            !checkingCookingParametersStatues(
              filterControlModes?.isTimeRequired
            ) && (
              <Typography variant="caption1" color="#EF4823">
                {isError[instructionArrayIndex]?.durationInMins?.message}
              </Typography>
            )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Grid xs={12} container spacing={2} display="flex">
          <Grid item xs={4}>
            <Typography mb={1} variant="subtext1">
              Cooking Action
            </Typography>
            <ModifiedAutoCompleteWithCookingActions
              setCookingAction={setCookingAction}
              variant="outlined"
              size={"small"}
              options={
                [...semiCompatible, ...rikuCompatible].some((i) =>
                  metaData?.productReleases.includes(i)
                )
                  ? cookingActions.map((item) => item.name)
                  : manualHeatOptions
              }
              control={control}
              clearErrors={clearErrors}
              name={`cookingParameters.${instructionArrayIndex}.action`}
              fullWidth={true}
              instructionArrayIndex={instructionArrayIndex}
              error={Boolean(
                isError && isError[instructionArrayIndex]?.controlModeId
              )}
              errorMessage={
                isError &&
                isError[instructionArrayIndex]?.controlModeId?.message
              }
            />
          </Grid>
          <Grid item xs={8} display="flex">
            {[...semiCompatible, ...rikuCompatible].some((i) =>
              metaData?.productReleases.includes(i)
            ) ? (
              renderingCookingParameter()
            ) : (
              <>
                <Grid item>
                  <Typography
                    variant="subtext1"
                    sx={{
                      opacity:
                        !checkingManualCookingParameters(
                          cookingParameters?.action
                        ) && 0.5,
                    }}
                  >
                    Heat level
                    {/* <span style={{ color: "#EF4823" }}>*</span> */}
                  </Typography>
                  <Box display="flex" height="44px">
                    {heatLevel.map((heatLevel, heatLevelIndex) => (
                      <Box
                        key={heatLevelIndex}
                        onClick={() =>
                          checkingManualCookingParameters(
                            cookingParameters?.action
                          ) && selectHeatLevelHandler(heatLevel)
                        }
                        variant="outlined"
                        sx={{
                          ...stylesheet.servingsButton,
                          border:
                            getHeatLevel === heatLevel &&
                            checkingManualCookingParameters(
                              cookingParameters?.action
                            )
                              ? "2px solid #EF4823"
                              : "2px solid #f1f1f1",
                          background:
                            getHeatLevel === heatLevel &&
                            checkingManualCookingParameters(
                              cookingParameters?.action
                            ) &&
                            "#FFECE4",
                          opacity:
                            !checkingManualCookingParameters(
                              cookingParameters?.action
                            ) && 0.5,
                        }}
                      >
                        <Typography
                          variant="subtext1"
                          sx={{
                            fontWeight: 500,
                            color:
                              getHeatLevel === heatLevel &&
                              checkingManualCookingParameters(
                                cookingParameters?.action
                              ) &&
                              "#EF4823",
                          }}
                        >
                          {heatLevel}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Grid>
                <Grid item display="flex" flexDirection="column" ml={2}>
                  <Typography
                    variant="subtext1"
                    sx={{
                      opacity: checkingManualCookingParametersTime(
                        cookingParameters?.action
                      )
                        ? 0.5
                        : 1,
                    }}
                  >
                    Time
                    {/* {!checkingCookingParametersStatues(
                  filterControlModes?.isTimeRequired
                ) && <span style={{ color: "#EF4823" }}> *</span>} */}
                  </Typography>

                  <ModifiedTextField
                    fullWidth
                    variant="outlined"
                    type="number"
                    placeholder="-"
                    size="small"
                    error={
                      isError && isError[instructionArrayIndex]?.durationInMins
                    }
                    style={{
                      width: "100px",
                    }}
                    control={control}
                    name={`cookingParameters.${instructionArrayIndex}.durationInMins`}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          opacity:
                            checkingManualCookingParametersTime(
                              cookingParameters?.action
                            ) && 0.5,
                        }}
                      >
                        <Typography variant="body4">mins</Typography>
                      </InputAdornment>
                    }
                    disabled={checkingManualCookingParametersTime(
                      cookingParameters?.action
                    )}
                  />
                  {isError &&
                    !checkingManualCookingParametersTime(
                      cookingParameters?.action
                    ) && (
                      <Typography variant="caption1" color="#EF4823">
                        {
                          isError[instructionArrayIndex]?.durationInMins
                            ?.message
                        }
                      </Typography>
                    )}
                </Grid>
              </>
            )}
          </Grid>

          {filterSection[0]?.sectionType === "hardware" && (
            <>
              <Grid item mr={1}>
                <Typography mb={1} variant="subtext1">
                  Stirring
                </Typography>
                <Box display="flex">
                  {["easy", "medium", "high", "reverse"].map((item, index) => (
                    <Box
                      onClick={() => handleStirringSelected(item)}
                      variant="outlined"
                      sx={{
                        ...stylesheet.servingsButton,
                        border:
                          item === stirring
                            ? "2px solid #EF4823"
                            : "2px solid #f1f1f1",
                        background:
                          item === stirring ? "#FFECE4" : "transparent",
                      }}
                    >
                      <Typography
                        variant="subtext1"
                        sx={{
                          fontWeight: 500,
                          color: item === stirring && "#EF4823",
                        }}
                      >
                        {item}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item>
                <Typography mb={1} variant="subtext1">
                  Lid
                </Typography>
                <Box display="flex">
                  {["on", "off"].map((item, index) => (
                    <Box
                      key={index}
                      onClick={() => handleLidSelection(item)}
                      variant="outlined"
                      sx={{
                        border:
                          lidOpen === (item === "on")
                            ? "2px solid #EF4823"
                            : "2px solid #f1f1f1",
                        background:
                          lidOpen === (item === "on")
                            ? "#FFECE4"
                            : "transparent",
                        ...stylesheet.servingsButton,
                      }}
                    >
                      <Typography
                        variant="subtext1"
                        sx={{
                          fontWeight: 500,
                          color:
                            lidOpen === (item === "on") ? "#EF4823" : "inherit",
                        }}
                      >
                        {item}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
