/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, Box, Snackbar, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import useEntity from "../entities/use-entity.js";
import { useAuth } from "../auth/auth-context.js";
import DeleteRecipeDialog from "../../components/delete-recipe-dialog.js";
import { useGlobalData } from "../../config/global-data-context.js";
import { recipeCards } from "../../utils/constants/index.js";

const RecipesTable = ({ isCreatedByKlynk }) => {
  const { user } = useAuth();
  const { isDelete, setIsDelete } = useGlobalData();
  const [rowData, setRowData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canClone, setCanClone] = useState(false);
  const navigate = useNavigate();
  const {
    loading,
    loadingMessage,
    getEntities,
    addUserLogsHandler,
    deleteEntities,
  } = useEntity();

  useEffect(() => {
    const loadData = async () => {
      await getEntities("recipeCards", setRowData, isCreatedByKlynk);
    };
    loadData();
    
    return () => setRowData([]);
  }, [isCreatedByKlynk]);

  const refreshData = async () => {
    await getEntities("recipeCards", setRowData, isCreatedByKlynk);
  };

  // Handle row selection
  const handleCloneRecipe = async (recipeID) => {
    if (recipeID[0]) {
      const clonedId = uuidv4();
      navigate(`/new-recipe-creation/${clonedId}&recipe=clone`, {
        state: { clonedId: clonedId, recipeId: recipeID[0] },
      });
    }
  };

  const deleteRecipes = async () => {
    if (selectedRows.length > 0) {
      setIsDelete(false);
      await deleteEntities(recipeCards, selectedRows);
      selectedRows.forEach(async () => await addUserLogsHandler());
    }
    setSelectedRows([]);
    await refreshData();
  };
  const formatDate = (dateValue) => {
    if (!dateValue) return "";
    const date = normalizeDate(dateValue);
    if (!date) return "";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const normalizeDate = (timestamp) => {
    if (!timestamp) return null;
    if (timestamp.seconds) return new Date(timestamp.seconds * 1000);
    if (timestamp instanceof Date) return timestamp;
    return new Date(timestamp);
  };

  const editRecipeOpen = () => {
    const editRecipe = rowData.find((val) => val.id === selectedRows[0]);
    if (editRecipe) {
      navigate(`/new-recipe-creation/${selectedRows[0]}&recipe=edit`);
    }
  };

  useEffect(() => {
    if (selectedRows.length === 0) {
      setCanDelete(false);
      setCanEdit(false);
      setCanClone(false);
    } else if (selectedRows.length === 1) {
      setCanEdit(true);
      setCanDelete(true);
      setCanClone(true);
    } else {
      setCanEdit(false);
      setCanDelete(true);
      setCanClone(false);
    }
  }, [selectedRows]);

  const columns = [
    {
      field: "title",
      headerName: "Recipe Title",
      width: 300,
      renderCell: (props) => (
        <Link
          key={props.row.recipeId}
          to={`/recipes/${props.row.recipeId}`}
          state={props.row}
        >
          {props.row.title || props.row.name}
        </Link>
      ),
    },
    {
      field: "authorName",
      headerName: "Author",
      width: 200,
      renderCell: (props) => (
        <span>{props.row.authorName || "Unknown Author"}</span>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      width: 150,
      valueGetter: (params) => normalizeDate(params.row.updatedAt),
      renderCell: (props) => (
        <span>
          {props.row.updatedAt ? formatDate(props.row.updatedAt) : ""}
        </span>
      ),
      sortComparator: (v1, v2) =>
        (normalizeDate(v1)?.getTime() || 0) -
        (normalizeDate(v2)?.getTime() || 0),
    },
    {
      field: "createdAt",
      type: "date",
      headerName: "Created At",
      width: 150,
      valueGetter: (params) => normalizeDate(params.row.createdAt),
      renderCell: (props) => (
        <span>
          {props.row.createdAt ? formatDate(props.row.createdAt) : ""}
        </span>
      ),
      sortComparator: (v1, v2) =>
        (normalizeDate(v1)?.getTime() || 0) -
        (normalizeDate(v2)?.getTime() || 0),
    },
    {
      field: "productReleases",
      headerName: "Compatibility",
      width: 250,
      valueGetter: (params) => {
        const releases = params.row.productReleases;
        return Array.isArray(releases) && releases.length > 0
          ? releases.join(" , ")
          : "Manual";
      },
      renderCell: (params) => (
        <span>
          {Array.isArray(params.row.productReleases) &&
          params.row.productReleases.length > 0
            ? params.row.productReleases.join(" , ")
            : "Manual"}
        </span>
      ),
    },
    {
      field: "isVerifiedByKlynk",
      headerName: "Verified By Klynk Team",
      width: 200,
      renderCell: (props) => (props.row.isVerifiedByKlynk ? "Yes" : "No"),
    },
  ];

  return (
    <Box padding={2} style={{ height: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={loading}
        message={loadingMessage}
        autoHideDuration={6000}
      />
      <DeleteRecipeDialog
        isDelete={isDelete}
        setIsDelete={setIsDelete}
        deleteRecipes={deleteRecipes}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h5">Recipes</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {(user?.access === "Admin" || user?.access === "Editor") && (
                <>
                  <IconButton
                    disabled={!canClone}
                    onClick={() => handleCloneRecipe(selectedRows)}
                  >
                    <FileCopyIcon />
                  </IconButton>
                  <IconButton onClick={() => navigate("/new-recipe-creation")}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  <IconButton disabled={!canEdit} onClick={editRecipeOpen}>
                    <ModeEditIcon />
                  </IconButton>
                </>
              )}
              {user?.access === "Admin" && (
                <IconButton
                  disabled={!canDelete}
                  onClick={() => setIsDelete(true)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: "85vh", width: "100%" }}>
            <DataGrid
              rows={rowData}
              getRowId={(row) => row.id}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[20]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(rows) => setSelectedRows(rows)}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RecipesTable;
