import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Typography,
  Collapse,
  Grid,
  Avatar,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import EggIcon from "@mui/icons-material/Egg";
import FilterListIcon from "@mui/icons-material/FilterList";
import SoupKitchenIcon from "@mui/icons-material/SoupKitchen";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import AdfScannerIcon from "@mui/icons-material/AdfScanner";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import PhotoCameraFrontIcon from "@mui/icons-material/PhotoCameraFront";
import ContactsIcon from "@mui/icons-material/Contacts";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ListIcon from "@mui/icons-material/List";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import CategoryIcon from "@mui/icons-material/Category";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import DangerousIcon from "@mui/icons-material/Dangerous";
import MonitorWeightIcon from "@mui/icons-material/MonitorWeight";
import StyleIcon from "@mui/icons-material/Style";
import FlatwareIcon from "@mui/icons-material/Flatware";
import StraightenIcon from "@mui/icons-material/Straighten";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import SwitchAccessShortcutAddIcon from "@mui/icons-material/SwitchAccessShortcutAdd";
import TuneIcon from "@mui/icons-material/Tune";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import QrCodeIcon from "@mui/icons-material/QrCode";
import TerminalIcon from "@mui/icons-material/Terminal";
import LogoutIcon from "@mui/icons-material/Logout";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import { useAuth } from "../pages/auth/auth-context";
import klynkLogo from "../assets/klynk-logo.png";

const drawerWidth = 260;

const useStyles = makeStyles({
  drawerContainer: {
    display: "flex",
    justifyContent: "center",
    height: "100vh",
  },
  drawer: {
    width: drawerWidth,
    backgroundColor: "#f5f5f5",
    color: "#000000",
    display: "flex",
    flexDirection: "column",
  },
  logoBox: {
    padding: 16,
    height: 80,
  },
  menuList: {
    flexGrow: 1,
    overflow: "auto",
  },
  menuItem: {
    margin: 5,
    padding: 5,
    background: "#cecece",
    width: drawerWidth - 15,
    borderRadius: 5,
  },
  menuItemOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  menuCollapse: {
    background: "#dbdbdb",
    margin: "0 5px",
    width: drawerWidth - 15,
    marginTop: -5,
    borderRadius: 5,
  },
  footer: {
    padding: 16,
    display: "flex",
    alignItems: "center",
    width: drawerWidth - 32,
    background: "#f5f5f5",
  },
});

const SingleLevel = ({ item }) => {
  const activeStyle = {
    textDecoration: "none",
    color: "green",
  };

  const inActiveStyle = {
    textDecoration: "none",
    color: "black",
  };

  return (
    <NavLink
      to={item.path}
      style={({ isActive }) => (isActive ? activeStyle : inActiveStyle)}
    >
      <ListItem>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText
          primary={
            item.title === "Dashboard" ? (
              <Typography variant="subHeading1">{item.title}</Typography>
            ) : (
              <Typography variant="body1">{item.title}</Typography>
            )
          }
        />
      </ListItem>
    </NavLink>
  );
};

const MultiLevel = ({ item, openMenuIndex, currentMenuIndex, setOpenMenuIndex }) => {
  const classes = useStyles();
  const { items: children } = item;

  const isOpen = String(openMenuIndex)?.startsWith(String(currentMenuIndex));

  const handleClick = () => {
    if (String(openMenuIndex) === String(currentMenuIndex)) {
      setOpenMenuIndex(null);
    } else {
      setOpenMenuIndex(currentMenuIndex);
    }
  };

  return (
    <>
      <ListItem
        onClick={handleClick}
        className={`${classes.menuItem} ${isOpen ? classes.menuItemOpen : ""}`}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText
          primary={<Typography variant="subHeading1">{item.title}</Typography>}
        />
        {children && (isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List className={classes.menuCollapse}>
          {children?.map((child, key) =>
            child.items ? (
              <MultiLevel
                item={child}
                key={key}
                openMenuIndex={openMenuIndex}
                currentMenuIndex={`${currentMenuIndex}-${key}`}
                setOpenMenuIndex={setOpenMenuIndex}
              />
            ) : (
              <SingleLevel item={child} key={key} />
            )
          )}
        </List>
      </Collapse>
    </>
  );
};

export default function AppSideNavigator() {
  const classes = useStyles();
  const { user, setOpenLogoutPopup } = useAuth();
  const [openMenuIndex, setOpenMenuIndex] = useState(0);

  const menuItems = [
    { title: "Dashboard", icon: <DashboardIcon />, path: "/" },
    {
      title: "Recipes",
      icon: <MenuBookIcon />,
      items: [
        ...(user?.access === "Admin" || user?.access === "Editor"
          ? [
              {
                title: "New Recipe Creation",
                path: "/new-recipe-creation",
                icon: <AddCircleIcon />,
              },
            ]
          : []),
        {
          title: "All Recipes",
          icon: <ListIcon />,
          items: [
            {
              title: "Klynk Recipes",
              path: "/recipes/klynk",
              icon: <ListIcon />,
            },
            {
              title: "User Recipes",
              path: "/recipes/user",
              icon: <ListIcon />,
            },
          ],
        },
      ],
    },
    {
      title: "Ingredients",
      icon: <EggIcon />,
      items: [{ title: "All Ingredients", path: "/ingredients", icon: <ListIcon /> }],
    },
    {
      title: "Filters",
      icon: <FilterListIcon />,
      items: [
        { title: "Aisle", path: "/aisles", icon: <ShoppingBasketIcon /> },
        { title: "Food Categories", path: "/food-categories", icon: <CategoryIcon /> },
        { title: "Cuisines", path: "/cuisines", icon: <RamenDiningIcon /> },
        { title: "Courses", path: "/courses", icon: <BedtimeIcon /> },
        { title: "Medical Conditions", path: "/medical-conditions", icon: <MedicalInformationIcon /> },
        { title: "Allergies", path: "/allergies", icon: <DangerousIcon /> },
        { title: "Diets", path: "/diets", icon: <MonitorWeightIcon /> },
        { title: "Tags", path: "/tags", icon: <StyleIcon /> },
      ],
    },
    {
      title: "Cooking",
      icon: <SoupKitchenIcon />,
      items: [
        { title: "Tools", path: "/tools", icon: <FlatwareIcon /> },
        { title: "Units", path: "/units", icon: <StraightenIcon /> },
        { title: "Prep Styles", path: "/prep-styles", icon: <CallSplitIcon /> },
        { title: "Actions", path: "/actions", icon: <WorkspacesIcon /> },
        { title: "Presets", path: "/presets", icon: <SwitchAccessShortcutAddIcon /> },
        { title: "Control Modes", path: "/control-modes", icon: <TuneIcon /> },
      ],
    },
    {
      title: "Appliances",
      icon: <PrecisionManufacturingIcon />,
      items: [
        { title: "Products", path: "/products", icon: <Inventory2Icon /> },
        { title: "Releases", path: "/product-releases", icon: <NewReleasesIcon /> },
      ],
    },
    { title: "Riku", icon: <AdfScannerIcon />, items: [{ title: "QR codes", path: "/qr-code-table", icon: <QrCodeIcon /> }] },
    { title: "Semi", icon: <TabletMacIcon />, items: [{ title: "WebSockets Test", path: "/semi-test", icon: <TerminalIcon /> }] },
    {
      title: "App",
      icon: <AppShortcutIcon />,
      items: [
        { title: "Recipe Filter", path: "/recipe-filters", icon: <FilterListIcon /> },
        { title: "Recipe Filter Groups", path: "/recipe-filter-groups", icon: <AutoAwesomeIcon /> },
      ],
    },
    {
      title: "Creators",
      icon: <PhotoCameraFrontIcon />,
      items: [{ title: "Leads", path: "/creator-leads", icon: <ContactsIcon /> }],
    },
    user?.access === "Admin" && {
      title: "User Logs",
      icon: <PhotoCameraFrontIcon />,
      items: [{ title: "All User Logs", path: "/user-logs", icon: <ListIcon /> }],
    },
  ].filter(Boolean);

  return (
    <Box className={classes.drawerContainer}>
      <Box className={classes.drawer}>
        <Box className={classes.logoBox}>
          <img src={klynkLogo} alt="Klynk.OS" style={{ height: "100%" }} />
        </Box>
        <List className={classes.menuList}>
          {menuItems.map((item, index) =>
            item.items ? (
              <MultiLevel
                item={item}
                key={index}
                openMenuIndex={openMenuIndex}
                currentMenuIndex={index}
                setOpenMenuIndex={setOpenMenuIndex}
              />
            ) : (
              <SingleLevel item={item} key={index} />
            )
          )}
        </List>
        <Box className={classes.footer}>
          <Grid container spacing={2}>
            <Grid item>
              <Avatar>{user?.name?.charAt(0)}</Avatar>
            </Grid>
            <Grid item>
              <Box>
                <Typography variant="subHeading1">{user?.name}</Typography>
                <Typography variant="body2">{user?.access}</Typography>
              </Box>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setOpenLogoutPopup(true)}>
                <LogoutIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box flexGrow={1}>
        <Outlet />
      </Box>
    </Box>
  );
}
